import axios from "axios";
import { useEffect, useState } from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";

const Footer = () => {

    const [courses, setCourses] = useState([]);
    // const [isalldata, setIsalldata] = useState(false);

    useEffect(() => {

        axios.get(`https://cmiapi-navy.vercel.app/getall_courses`)
            .then(function (res) {
                setCourses(res.data.data.slice(0, 5))
                // setIsalldata(true)
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
    }, [])

    return (
        <>
            <ToastContainer />
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={'/'}>
                                            <img
                                                className="logo-light"
                                                src="/assets/images/logo/logo-white.webp"
                                                alt="Codesign"
                                                title="Codesign"
                                            />
                                        </Link>
                                    </div>
                                    <p className="description">
                                        Codesign Multimedia Institute is leading computer training institute in surat. We offers various type of computer training courses in Surat.
                                    </p>
                                    <ul className="social-share">
                                        <li>
                                            <a href="https://www.instagram.com/codesign_multimedia_institute/">
                                                <i class="fi fi-brands-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=100088854465381">
                                                <i>
                                                    <FaFacebookF className="fb-icon" />
                                                </i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/90445650">
                                                <FaLinkedinIn className="fb-icon" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/Codesign402?t=7T69Ab-xm1UqvDxx15PY3Q&s=08">
                                                <i className="icon-Twitter" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Explore</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">

                                            {
                                                courses.map((item, index) => {
                                                    return (
                                                        <li key={`Course${index}`}>
                                                            <Link to={`/course/${item._id}`}>
                                                                <i className="icon-Double-arrow" />
                                                                {item.title}
                                                            </Link>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">Useful Links</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li>
                                                <Link to={'/about'}>
                                                    <i className="icon-Double-arrow" />
                                                    About
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/course'}>
                                                    <i className="icon-Double-arrow" />
                                                    Courses
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/blog'}>
                                                    <i className="icon-Double-arrow" />
                                                    Blog
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/event'}>
                                                    <i className="icon-Double-arrow" />
                                                    Event
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/gallery'}>
                                                    <i className="icon-Double-arrow" />
                                                    Gallery
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contact Info</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li>
                                                    <i className="icon-map-pin-line" />
                                                    402, Middle point , Near dhara arcade , Mahadevchowk , Mota varachha , Surat.
                                                </li>
                                                <li>
                                                    <i className="icon-phone-fill" />
                                                    <a href="tel:+918160254080">+ 91 81602 54080</a>
                                                </li>
                                                <li>
                                                    <i className="icon-mail-line-2" />
                                                    <a
                                                        target="_blank"
                                                        href="mailto:codesignmultimedia@gmail.com"
                                                        rel="noreferrer"
                                                    >
                                                        codesignmultimedia@gmail.com
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/assets/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/assets/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>
                                        Copyright 2024 @ <Link to={'/'}>Codesign Multimedia Institute</Link>. All
                                        Rights Reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;