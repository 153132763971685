import axios from "axios"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Bounce, toast } from "react-toastify"

const Contact = () => {


    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    

    const submit_data = (e) => {
        e.preventDefault();
        if (name && email && phone && subject && message) {
            axios.post('https://cmiapi-navy.vercel.app/contact', {
                name: name,
                email: email,
                subject: subject,
                phone: phone,
                message: message

            })
                .then(function (res) {
                    toast.success("Thank You!! Our team will contact you shortly...", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                    setName('')
                    setEmail('')
                    setSubject('');
                    setPhone('');
                    setMessage('');
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error(error.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                });
        } else {
            toast.warning("Please fill out all fields", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            
        }
    }

    return (
        <>
            <Helmet>
                <title>Get in touch with us | Computer Course in Surat | Codesign</title>
                <meta name="title" content="Get in touch with us | Computer Course in Surat | Codesign" data-rh="true" />
                <meta name="description" content="Codesign is a Best Software Training Institute in Surat with 100% Placements for Digital Marketing, Web Development, Web Design, android training in surat" data-rh="true" />
                <meta property="og:title" content="Get in touch with us | Computer Course in Surat | Codesign" data-rh="true" />
                <meta property="og:description" content="Codesign is a Best Software Training Institute in Surat with 100% Placements for Digital Marketing, Web Development, Web Design, android training in surat" data-rh="true" />
                <meta name="twitter:title" content="Get in touch with us | Computer Course in Surat | Codesign" data-rh="true" />
                <meta name="twitter:description" content="Codesign is a Best Software Training Institute in Surat with 100% Placements for Digital Marketing, Web Development, Web Design, android training in surat" data-rh="true" />
            </Helmet>
            <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-start">
                                <div className="page-title">
                                    <h3 className="title">Contact Us</h3>
                                </div>
                                <nav className="edu-breadcrumb-nav">
                                    <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="separator">
                                            <i className="ri-arrow-drop-right-line" />
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Contact Us
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-4">
                                <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-5">
                                <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-6">
                                <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="edu-contact-us-area eduvibe-contact-us edu-section-gap bg-color-white">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div
                                className="section-title text-center"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">Need Help?</span>
                                <h3 className="title">Get In Touch With us</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mt--20">
                        <div className="col-lg-6">
                            <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                                <div className="row g-5">
                                    {/* Start Contact Info  */}
                                    <div
                                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="contact-address-card-1 website">
                                            <div className="inner">
                                                <div className="icon">
                                                    <i className="ri-global-line" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Our Website</h6>
                                                    <p>
                                                        <a href="https://codesignmultimedia.com/" target="_blank" rel="noreferrer">
                                                            www.codesignmultimedia.com
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Contact Info  */}
                                    {/* Start Contact Info  */}
                                    <div
                                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        data-sal-delay={200}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="contact-address-card-1 phone">
                                            <div className="inner">
                                                <div className="icon">
                                                    <i className="icon-Headphone" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Call Us On</h6>
                                                    <p>
                                                        <a href="tel:+91 81602 54080">+91 81602 54080</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Contact Info  */}
                                    {/* Start Contact Info  */}
                                    <div
                                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        data-sal-delay={250}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="contact-address-card-1 email">
                                            <div className="inner">
                                                <div className="icon">
                                                    <i className="icon-mail-open-line" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Email Us</h6>
                                                    <p>
                                                        <a href="mailto:info@codesignmultimedia.com" target="_blank" rel="noreferrer">
                                                            info@codesignmultimedia.com <br /><br />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Contact Info  */}
                                    {/* Start Contact Info  */}
                                    <div
                                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                                        data-sal-delay={300}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="contact-address-card-1 location">
                                            <div className="inner">
                                                <div className="icon">
                                                    <i className="icon-map-pin-line" />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Our Location</h6>
                                                    <p>402 , Middle Point , Mota Varachha , Surat.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Contact Info  */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form
                                className="rnt-contact-form rwt-dynamic-form row"
                                id="contact-form"
                            >
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input
                                            name="contact-name"
                                            id="contact-name"
                                            type="text"
                                            value={name}
                                            onChange={(e) => { setName(e.target.value) }}
                                            className="form-control form-control-lg"
                                            placeholder="Name*"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control form-control-lg"
                                            onChange={(e) => { setEmail(e.target.value) }}
                                            id="contact-email"
                                            value={email}
                                            name="contact-email"
                                            placeholder="Email*"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            name="contact-phone"
                                            id="contact-phone"
                                            value={phone}
                                            onChange={(e) => { setPhone(e.target.value) }}
                                            placeholder="Phone"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="subject"
                                            value={subject}
                                            onChange={(e) => { setSubject(e.target.value) }}
                                            name="subject"
                                            placeholder="Subject"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            name="contact-message"
                                            value={message}
                                            id="contact-message"
                                            onChange={(e) => { setMessage(e.target.value) }}
                                            placeholder="Your Message"
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <button
                                        className="rn-btn edu-btn w-100"
                                        name="submit"
                                        type="submit"
                                        onClick={submit_data}
                                    >
                                        <span>Submit Now</span>
                                        <i className="icon-arrow-right-line-right" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image scene shape-image-1">
                            <span data-depth="-2.2">
                                <img src="assets/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                            </span>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-02-08.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="assets/images/shapes/shape-15.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="edu-contact-map-area edu-section-gapBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="google-map alignwide"
                                data-sal="slide-up"
                                data-sal-delay={150}
                                data-sal-duration={800}
                            >
                                <iframe
                                    title="Codesign Location"
                                    className="radius-small"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.8742442045004!2d72.88234441533888!3d21.236834786017166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04fb6166e6473%3A0x654e32ec4b18c578!2sCodesign%20Multimedia%20Institute!5e0!3m2!1sen!2sin!4v1674996979919!5m2!1sen!2sin"
                                    height={500}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Contact