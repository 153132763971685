import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";

const CourseHome = () => {

    const [course, setCourse] = useState([]);
    // const [isdata, setIsdata] = useState(false);

    var loadScript = function (src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
    }

    useEffect(() => {
        axios.get('https://cmiapi-navy.vercel.app/getall_courses')
            .then(function (res) {
                loadScript('assets/js/vendor/sal.min.js');
                setCourse(res.data.data)
                // setIsdata(true)
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="eduvibe-home-four-courses edu-course-area edu-section-gap bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="section-title text-center"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">Featured Courses</span>
                                <h3 className="title">Explore Our Popular Courses</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mt--10">

                        {
                            course.slice(0, 4).map((course, index) => {
                                return (
                                    <div
                                        key={`Course${index}`}
                                        className="col-12 col-sm-6 col-lg-6"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="edu-card card-type-4 radius-small eduvibe-course-style-four bg-white">
                                            <div className="inner">
                                                <div className="thumbnail">
                                                    <Link to={`/course/${course._id}`}>
                                                        <img
                                                            className="w-100"
                                                            src={course.thumbnail}
                                                            alt={`Codesign - Best ${course.title} Training institute in Surat`}
                                                            title={`Codesign - Best ${course.title} Training institute in Surat`}
                                                        />
                                                    </Link>
                                                    <div className="wishlist-top-right">
                                                        <button className="wishlist-btn">
                                                            <i className="icon-Heart" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <ul className="edu-meta meta-03">
                                                        <li className="meta-lessons">
                                                            <i className="icon-file-list-4-line" />
                                                            {course.duration}
                                                        </li>
                                                        <li className="meta-clock">
                                                            <i className="icon-time-line" />
                                                            2 hours/day
                                                        </li>
                                                    </ul>
                                                    <h6 className="title">
                                                        <Link to={`/course/${course._id}`}>{course.title}</Link>
                                                    </h6>
                                                    <div className="card-bottom">
                                                        <div className="edu-rating rating-default">
                                                            <div className="rating">
                                                                <i className="icon-Star" />
                                                                <i className="icon-Star" />
                                                                <i className="icon-Star" />
                                                                <i className="icon-Star" />
                                                                <i className="icon-Star" />
                                                            </div>
                                                            <span className="rating-count">({course.rating})</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className="row view-btn">
                        <div className="col-lg-12">
                            <div className="load-more-btn mt--60 text-center">
                                <Link className="edu-btn" to={'/course'}>
                                    View All Courses
                                    <i className="icon-arrow-right-line-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-28.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-15-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="assets/images/shapes/shape-03-09.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="assets/images/shapes/shape-04-06.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CourseHome