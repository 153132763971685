import { Link } from "react-router-dom"

const Services = () => {
    return (
        <div className="eduvibe-home-four-service edu-service-area edu-section-gap bg-color-white position-relative border-bottom-1">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="section-title text-center"
                            data-sal-delay={150}
                            data-sal="slide-up"
                            data-sal-duration={800}
                        >
                            <span className="pre-title">What We Offer</span>
                            <h3 className="title">
                                Learn New Skills When And <br /> Which You Like
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row eduvibe-about-one-service g-5 mt--20">
                    {/* Start Service Grid  */}
                    <div
                        className="col-lg-3 col-md-6 col-12"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="service-card service-card-3 text-left shape-bg-1 bg-grey">
                            <div className="inner">
                                <div className="icon">
                                    <Link to={'/about'}><i class="fi fi-rr-diploma"></i></Link>
                                </div>
                                <div className="content">
                                    <h6 className="title">
                                        <Link to={'/about'}>Online Certifications</Link>
                                    </h6>
                                    <p className="description">
                                        Get certified online, train offline, enhance skills, improve career.
                                    </p>
                                    <div className="read-more-btn">
                                        <Link to={'/about'} className="btn-transparent sm-size heading-color">
                                            Learn More
                                            <i className="icon-arrow-right-line-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Service Grid  */}
                    {/* Start Service Grid  */}
                    <div
                        className="col-lg-3 col-md-6 col-12"
                        data-sal-delay={200}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="service-card service-card-3 text-left shape-bg-2 bg-grey">
                            <div className="inner">
                                <div className="icon">
                                    <Link to={'/about'}><i class="fi fi-rr-e-learning"></i></Link>
                                </div>
                                <div className="content">
                                    <h6 className="title">
                                        <Link to={'/about'}>Expert Trainers</Link>
                                    </h6>
                                    <p className="description">
                                        Learn from industry experts. Enhance skills. Boost career growth.
                                    </p>
                                    <div className="read-more-btn">
                                        <Link to={'/about'} className="btn-transparent sm-size heading-color">
                                            Learn More
                                            <i className="icon-arrow-right-line-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Service Grid  */}
                    {/* Start Service Grid  */}
                    <div
                        className="col-lg-3 col-md-6 col-12"
                        data-sal-delay={250}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="service-card service-card-3 text-left shape-bg-3 bg-grey">
                            <div className="inner">
                                <div className="icon">
                                    <Link to={'/about'}><i class="fi fi-rr-microchip"></i></Link>
                                </div>
                                <div className="content">
                                    <h6 className="title">
                                        <Link to={'/about'}>Latest Technology</Link>
                                    </h6>
                                    <p className="description">
                                        Upgrade skills with cutting-edge tech training. Stay current, succeed.
                                    </p>
                                    <div className="read-more-btn">
                                        <Link to={'/about'} className="btn-transparent sm-size heading-color">
                                            Learn More
                                            <i className="icon-arrow-right-line-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Service Grid  */}
                    {/* Start Service Grid  */}
                    <div
                        className="col-lg-3 col-md-6 col-12"
                        data-sal-delay={300}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="service-card service-card-3 text-left shape-bg-4 bg-grey">
                            <div className="inner">
                                <div className="icon">
                                    <Link to={'/about'}><i class="fi fi-rr-handshake"></i></Link>
                                </div>
                                <div className="content">
                                    <h6 className="title">
                                        <Link to={'/about'}>Lifetime Support</Link>
                                    </h6>
                                    <p className="description">
                                        Life-long student support for continuous learning and professional growth.
                                    </p>
                                    <div className="read-more-btn">
                                        <Link to={'/about'} className="btn-transparent sm-size heading-color">
                                            Learn More
                                            <i className="icon-arrow-right-line-right" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Service Grid  */}
                </div>
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-29.png" alt="Codesign Multimedia Institute" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-03-06.png" alt="Best IT training Institute in Surat" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-02-06.png" alt="Computer Courses In Surat" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="assets/images/shapes/shape-19-02.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Services