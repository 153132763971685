import { Skeleton } from "@mui/material"

const Skeletoon = () => {
    return (
        <>
            <div className="col-lg-4 col-md-6 col-12" >
                <div className="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                    <div className="inner">
                        <div className="thumbnail">
                            <Skeleton variant="rectangular" width={"100%"} height={220} />
                        </div>
                        <div className="">
                            <Skeleton />
                            <Skeleton width="60%" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Card 2 */}
            <div className="col-lg-4 col-md-6 col-12" >
                <div className="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                    <div className="inner">
                        <div className="thumbnail">
                            <Skeleton variant="rectangular" width={"100%"} height={220} />
                        </div>
                        <div>
                            <Skeleton />
                            <Skeleton width="60%" />
                        </div>
                    </div>
                </div>
            </div>
            {/* Card 3 */}
            <div className="col-lg-4 col-md-6 col-12" >
                <div className="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                    <div className="inner">
                        <div className="thumbnail">
                            <Skeleton variant="rectangular" width={"100%"} height={220} />
                        </div>
                        <div className="">
                            <Skeleton />
                            <Skeleton width="60%" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Skeletoon