const Testimonial = () => {
    return (
        <div className="eduvibe-home-four-testimonial edu-testimonial-area edu-section-gap bg-color-white">
            {/* Start Testimonial Area  */}
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="testimonial-left-image pr--80">
                            <div className="thumbnail">
                                <div className="circle-image-wrapper">
                                    <img
                                        className="radius-round"
                                        src="assets/images/testimonial-section/client-banner-left.jpg"
                                        alt="Codesign - Testimonials"
                                        title="Codesign - Testimonials"
                                    />
                                    <div className="circle-image">
                                        <span />
                                        <span />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-wrapper">
                            <div
                                className="section-title text-start mb--40"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">Testimonial</span>
                                <h3 className="title">Our Students Feedback</h3>
                            </div>
                            <div className="testimonial-activation testimonial-style-2">
                                {/* Start Single Testimonial  */}
                                <div className="testimonial-nav-activation">
                                    <div className="testimonial-nav-content">
                                        <p className="description">
                                            “ Excellent IT training institute! Knowledgeable faculty, practical-oriented approach, and supportive staff. Enhanced my technical skills and got detailed knowledge about React JS Development. Highly recommended!”
                                        </p>
                                        <div className="client-info">
                                            <h6 className="title">Vishal Sankhat</h6>
                                            <span className="designation">React JS Developer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-nav-content">
                                        <p className="description">
                                            “ Codesign Multimedia institute provides excellent courses with practical knowledge. Knowledgeable instructors, supportive staff, and useful resources. Highly recommend for IT education.”
                                        </p>
                                        <div className="client-info">
                                            <h6 className="title">Reena Ramani</h6>
                                            <span className="designation">Fullstack JS Developer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-nav-content">
                                        <p className="description">
                                            “ The atmosphere here is very conducive to learning, and the teaching methods of the tutors are very good and understandable. Whatever doubts I have are solved in the classroom as well as the trainers have given me personal attention to solve my queries.The Codesign Multimedia Institute is the best IT training institute in Surat.”
                                        </p>
                                        <div className="client-info">
                                            <h6 className="title">Hiren Sakariya</h6>
                                            <span className="designation">Web Designer</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-nav-content">
                                        <p className="description">
                                            “ I am learning Digital Marketing Course from Codesign Multimedia Institute. After study I have 2 years of gap and I don't know how to start my IT career again but Ruchita Shiroya Mam is great in teaching and she is helpful in solving doubts and queries. My overall experience with Codesign Multimedia Institute is good.”
                                        </p>
                                        <div className="client-info">
                                            <h6 className="title">Darshit Korat</h6>
                                            <span className="designation">Digital Marketer</span>
                                        </div>
                                    </div>
                                </div>
                                {/* End Single Testimonial  */}
                                <div className="testimonial-nav-wrapper">
                                    <div className="testimonial-nav-button">
                                        <div className="single-thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-02/vishal.jpg"
                                                alt="Codesign - Vishal Sankhat"
                                                title="Codesign - Vishal Sankhat"
                                            />
                                            <div className="loader-container">
                                                <div className="circle-loader-wrap">
                                                    <div className="left-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                    <div className="right-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-02/reena.jpg"
                                                alt="Codesign - Reena Ramani"
                                                title="Codesign - Reena Ramani"
                                            />
                                            <div className="loader-container">
                                                <div className="circle-loader-wrap">
                                                    <div className="left-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                    <div className="right-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-02/hiren.jpg"
                                                alt="Codesign - Hiren Sakariya"
                                                title="Codesign - Hiren Sakariya"
                                            />
                                            <div className="loader-container">
                                                <div className="circle-loader-wrap">
                                                    <div className="left-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                    <div className="right-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-02/darshit.jpg"
                                                alt="Codesign - Darshit Korat"
                                                title="Codesign - Darshit Korat"
                                            />
                                            <div className="loader-container">
                                                <div className="circle-loader-wrap">
                                                    <div className="left-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                    <div className="right-wrap">
                                                        <div className="loader" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-25-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-11-04.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
            {/* End Countdown Area  */}
        </div>

    )
}

export default Testimonial