import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import Skeletoon from "../components/Skeletoon";

const Course = () => {

    const [course, setCourse] = useState([]);
    const [isdata, setIsdata] = useState(false);


    useEffect(() => {
        window.scrollTo(0,0);
        axios.get('https://cmiapi-navy.vercel.app/getall_courses')
            .then(function (res) {
                setCourse(res.data.data.reverse())
                setIsdata(true)
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
    }, [])

    return (
        <>
            <Helmet>
                <title>Computer Training in Surat | Codesign</title>
                <meta name="title" content="Computer Training in Surat | Codesign" data-rh="true" />
                <meta name="description" content="Get the best computer course training in Surat with Codesign Multimedia Institute. Learn coding and design from industry experts today." data-rh="true" />
                <meta property="og:title" content="Computer Training in Surat | Codesign" data-rh="true" />
                <meta property="og:description" content="Get the best computer course training in Surat with Codesign Multimedia Institute. Learn coding and design from industry experts today." data-rh="true" />
                <meta name="twitter:title" content="Computer Training in Surat | Codesign" data-rh="true" />
                <meta name="twitter:description" content="Get the best computer course training in Surat with Codesign Multimedia Institute. Learn coding and design from industry experts today." data-rh="true" />
            </Helmet>
            <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner text-start">
                                <div className="page-title">
                                    <h3 className="title">Our Courses</h3>
                                </div>
                                <nav className="edu-breadcrumb-nav">
                                    <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                        <li className="breadcrumb-item">
                                            <a href="/">Home</a>
                                        </li>
                                        <li className="separator">
                                            <i className="ri-arrow-drop-right-line" />
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Courses
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-4">
                                <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-5">
                                <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-6">
                                <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="edu-course-area edu-section-gap bg-color-white main-courses mb-6">
                <div className="container">
                    <div className="row g-5 mt--10">
                        {/* Start Single Card  */}
                        {
                          (isdata)?(
                            course.map((course, index) => {
                                return (
                                    <div
                                        className="col-12 col-sm-6 col-lg-4"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                        key={`Course${index}`}
                                    >
                                        <div className="edu-card card-type-2 radius-small">
                                            <div className="inner">
                                                <div className="thumbnail">
                                                    <Link to={`/course/${course._id}`}>
                                                        <img
                                                            className="w-100"
                                                            src={course.thumbnail}
                                                            alt={course.meta_title}
                                                            title={course.meta_title}
                                                        />
                                                    </Link>
                                                    <div className="top-position status-group left-top">
                                                        <span className="eduvibe-status status-02">
                                                            <i class="fi fi-rr-calendar-clock"></i> {course.duration}
                                                        </span>
                                                    </div>
                                                    <div className="wishlist-top-right">
                                                        <button className="wishlist-btn">
                                                            <i className="icon-Heart" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="card-top">
                                                        <div className="edu-rating rating-default">

                                                        </div>
                                                    </div>
                                                    <h6 className="title">
                                                        <Link to={`/course/${course._id}`}>{course.title}</Link>
                                                    </h6>
                                                    <ul className="edu-meta meta-01">
                                                        <li>
                                                            <i class="fi fi-rr-clock-seven"></i>
                                                            2 Hours / Day
                                                        </li>
                                                        <li>
                                                            <div className="rating">
                                                                <i className="icon-Star" />
                                                            </div>
                                                            <span className="rating-count">({course.rating})</span>
                                                        </li>
                                                    </ul>
                                                    <div className="card-bottom">
                                                        <Link className="price-list price-style-01" to={`/course/${course._id}`}>
                                                            <div className="price current-price">Learn More</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                          ):(
                            <Skeletoon/>
                          )
                        }
                        {/* End Single Card  */}
                    </div>
                </div>
            </div>
        </>

    )
}

export default Course