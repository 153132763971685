import { useEffect } from "react";
import Comming from "./Comming";

const BlogSingle = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    

    return (

        <>
        <Comming/>
        </>
    )
}

export default BlogSingle;