import { useEffect } from "react";
import WOW from 'wowjs';
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const About = () => {

    var loadScript = function (src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
    }

    useEffect(() => {
        window.scrollTo(0,0);
        const wow = new WOW.WOW();
        wow.init();
        loadScript('assets/js/vendor/modernizr.min.js')
        loadScript('assets/js/vendor/jquery.js');
        loadScript('assets/js/vendor/bootstrap.min.js');
        loadScript('assets/js/vendor/sal.min.js');
        loadScript('assets/js/vendor/backtotop.js');
        loadScript('assets/js/vendor/magnifypopup.js');
        loadScript('assets/js/vendor/slick.js');
        loadScript('assets/js/vendor/countdown.js');
        loadScript('assets/js/vendor/jquery-appear.js');
        loadScript('assets/js/vendor/odometer.js');
        loadScript('assets/js/vendor/isotop.js');
        loadScript('assets/js/vendor/imageloaded.js');
        loadScript('assets/js/vendor/lightbox.js');
        loadScript('assets/js/vendor/wow.js');
        loadScript('assets/js/vendor/paralax.min.js');
        loadScript('assets/js/vendor/paralax-scroll.js');
        loadScript('assets/js/vendor/jquery-ui.js');
        loadScript('assets/js/vendor/tilt.jquery.min.js');
        loadScript('assets/js/main.js');
    }, [])

    return (
        <>
            <Helmet>
                <title>About Us | Leading Computer Course Provider in Surat</title>
                <meta name="title" content="About Us | Leading Computer Course Provider in Surat" data-rh="true" />
                <meta name="description" content="Join CMI - Top computer course institute in Surat with expert trainers, live projects, and assured placement support." data-rh="true" />
                <meta property="og:title" content="About Us | Leading Computer Course Provider in Surat" data-rh="true" />
                <meta property="og:description" content="Join CMI - Top computer course institute in Surat with expert trainers, live projects, and assured placement support." data-rh="true" />
                <meta name="twitter:title" content="About Us | Leading Computer Course Provider in Surat" data-rh="true" />
                <meta name="twitter:description" content="Join CMI - Top computer course institute in Surat with expert trainers, live projects, and assured placement support." data-rh="true" />
            </Helmet>
            <Breadcrumb course={"About Codesign"} />
            <div className="home-three-about edu-about-area about-style-4 bg-color-white edu-section-gapTop pb-4">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-lg-5 g-md-5 g-sm-5">
                        <div className="col-lg-12 col-xl-6">
                            <div className="gallery-wrapper">
                                <img
                                    className="image-1"
                                    src="/assets/images/about/about-05/about-group-01.jpg"
                                    alt="About - Codesign Multimedia"
                                    title="About - Codesign Multimedia"
                                />
                                <img
                                    className="image-2"
                                    src="/assets/images/about/about-05/about-group-02.jpg"
                                    alt="About Codesign Multimedia"
                                    title="About Codesign Multimedia"
                                />
                                <img
                                    className="image-3"
                                    data-parallax='{"x": 0, "y": -120}'
                                    src="/assets/images/about/about-05/about-group-03.jpg"
                                    alt="About Codesign Multimedia"
                                    title="About Codesign Multimedia"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-xl-6">
                            <div className="inner mt_mobile--40">
                                <div
                                    className="section-title text-start"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    <span className="pre-title">Who We Are</span>
                                    <h3 className="title">We Offer The Best Carrier</h3>
                                </div>
                                <div className="feature-style-6 mt--40">
                                    <div
                                        className="edu-feature-list color-variation-1"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="icon">
                                            <img
                                                src="assets/images/icons/student.png"
                                                alt="Codesign Multimedia Institute - Student"
                                            />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Industry Expert Instructor</h6>
                                            <p className="description">
                                                Industry experts instruct IT training courses, ensuring up-to-date knowledge and practical skills for learners.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="edu-feature-list color-variation-2"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="icon">
                                            <img src="assets/images/icons/book.png" alt="Icons Images" />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">Up-to-Date Course Content</h6>
                                            <p className="description">
                                                Our courses offer current and relevant content to keep learners informed and competitive in their field.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="edu-feature-list color-variation-3"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="icon">
                                            <img
                                                src="assets/images/icons/reward.png"
                                                alt="Icons Images"
                                            />
                                        </div>
                                        <div className="content">
                                            <h6 className="title">100% Placements With Lifetime Support</h6>
                                            <p className="description">
                                                Our program guarantees lifelong support and job placement assistance for all students, ensuring career success.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="read-more-btn mt--30"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    <Link className="edu-btn" to={'/contact'}>
                                        Contact Us <i className="icon-arrow-right-line-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-27.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="eduvibe-home-five-progress edu-service-area edu-section-gap bg-image">
                {/* Start Service Area  */}
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="section-title text-center"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">What We Offer</span>
                                <h3 className="title">How Does CodeSign Work?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mt--20">
                        {/* Start Service Grid  */}
                        <div
                            className="service-card-single col-lg-4 col-md-6 col-12"
                            data-sal-delay={150}
                            data-sal="slide-up"
                            data-sal-duration={800}
                        >
                            <div className="service-card service-card-7 shape-bg-1">
                                <div className="inner">
                                    <div className="icon">
                                        <i class="fi fi-rr-e-learning"></i>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">
                                            Choose Any Course
                                        </h6>
                                        <p className="description">
                                            Select from a wide range of courses to meet your educational and career goals.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Service Grid  */}
                        {/* Start Service Grid  */}
                        <div
                            className="service-card-single col-lg-4 col-md-6 col-12"
                            data-sal-delay={150}
                            data-sal="slide-up"
                            data-sal-duration={800}
                        >
                            <div className="service-card service-card-7 shape-bg-2">
                                <div className="inner">
                                    <div className="icon">
                                        <i class="fi fi-rr-book-arrow-right"></i>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">
                                            Start Learning
                                        </h6>
                                        <p className="description">
                                            Begin your educational journey and acquire new knowledge and skills to advance your career.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Service Grid  */}
                        {/* Start Service Grid  */}
                        <div
                            className="service-card-single col-lg-4 col-md-6 col-12"
                            data-sal-delay={150}
                            data-sal="slide-up"
                            data-sal-duration={800}
                        >
                            <div className="service-card service-card-7 shape-bg-3">
                                <div className="inner">
                                    <div className="icon">
                                        <i className="icon-trophy-line" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">
                                            Great! Get Placement
                                        </h6>
                                        <p className="description">
                                            Secure your dream job with our comprehensive job placement support and guidance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Service Grid  */}
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-07-02.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-04-07.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="assets/images/shapes/shape-13-11.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
            </div>
            <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
                <div className="container eduvibe-animated-shape">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail mb--50">
                                <div className="shape-image image-1">
                                    <img
                                        src="assets/images/mission/mission-gallery-01.jpg"
                                        data-parallax='{"x": 0, "y": 80}'
                                        alt="Codesign Multimedia - Gallery Images"
                                    />
                                </div>
                                <div className="shape-image image-2">
                                    <img
                                        src="assets/images/mission/mission-gallery-02.jpg"
                                        data-parallax='{"x": 0, "y": -60}'
                                        alt="Codesign Multimedia - Gallery Images"
                                    />
                                </div>
                                <div className="shape-image image-3">
                                    <img
                                        src="assets/images/mission/mission-gallery-03.jpg"
                                        data-parallax='{"x": 0, "y": 80}'
                                        alt="Codesign Multimeida - Gallery Images"
                                    />
                                </div>
                                <div className="shape-image image-4">
                                    <img
                                        src="assets/images/mission/mission-gallery-04.jpg"
                                        data-parallax='{"x": 0, "y": -80}'
                                        alt="Codesign Multimeida - Gallery Images"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="animated-image shape-image-1">
                            <img src="assets/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape shape-1">
                            <span className="shape-dot" />
                        </div>
                        <div className="shape shape-2">
                            <span className="shape-dot" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <div className="our-mission">
                                <div
                                    className="section-title text-start"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    <span className="pre-title">Our Mission</span>
                                    <h3 className="title">
                                        The Mission Behind <br /> Education Platform
                                    </h3>
                                    <p className="mt--30 mb--0">
                                        At <b>Codesign Multimedia Institute</b>, our mission is to empower students with the skills and knowledge necessary to succeed in a rapidly evolving industry. We strive to provide comprehensive, up-to-date instruction that prepares students for a wide range of careers. Our ultimate goal is to help students reach their full potential and achieve their professional aspirations through practical education and personalized support.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="our-vision">
                                <div
                                    className="section-title text-start"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    <span className="pre-title">Our Vision</span>
                                    <h3 className="title">
                                        The Story of Our <br /> Founders
                                    </h3>
                                    <p className="mt--30 mb--0">
                                        <b>Codesign Multimedia Institute</b> was founded by a group of industry experts who recognized the need for accessible and practical education in the digital age. Drawing on their extensive experience, they created a dynamic learning environment that fosters creativity, collaboration, and innovation. Their vision is to empower students with the skills and knowledge necessary to succeed in the rapidly evolving world of technology, and to make quality education accessible to all.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End Counterup Area  */}
            <div className="eduvibe-home-three-testimonial edu-testimonial-area testimonial-four-wrapper edu-section-gap bg-image counterup-overlay-top">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-8">
                            <div className="testimonial-activation pr--55 pr_lg--0 pr_md--0 pr_sm--0">
                                {/* Start Tastimonial Card  */}
                                <div className="testimonial-card-box variation-2">
                                    <div className="eduvibe-testimonial-three inner testimonial-card-activation-1 slick-arrow-style-2">
                                        <div className="single-card">
                                            <div className="rating">
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                            </div>
                                            <p className="description">
                                                “My experience is very good at Codesign Multimedia Institute. They are providing the best guidance on training in all types of Courses and projects. I will definitely recommend all of you to join this IT Academy. ”
                                            </p>
                                            <div className="client-info">
                                                <div className="thumbnail">
                                                    <img
                                                        src="assets/images/testimonial/testimonial-02/sahil-a.jpg"
                                                        alt="CMI - Sahil Ramoliya"
                                                        title="CMI - Sahil Ramoliya"
                                                    />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Sahil Ramoliya</h6>
                                                    <span className="designation">Backend Developer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="rating">
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                            </div>
                                            <p className="description">
                                                “I am very glad that I found this institute. In this training institute staff are very helpful, experienced and they make things easier. I strongly recommend Codesign Multimedia Institute .”
                                            </p>
                                            <div className="client-info">
                                                <div className="thumbnail">
                                                    <img
                                                        src="assets/images/testimonial/testimonial-02/jenil-a.jpg"
                                                        alt="CMI - Jenil Mangukiya"
                                                        title="CMI - Jenil Mangukiya"
                                                    />
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Jenil Mangukiya</h6>
                                                    <span className="designation">MERN Stack Developer</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-card">
                                            <div className="rating">
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                                <i className="on icon-Star" />
                                            </div>
                                            <p className="description">
                                                “This institute is best place for all people who want to set their career in IT field and get great job placement. Their teaching method is just amazing. Anyone can easily understand and learn so much new technologies. Special thanks to Bhavin sir for their support.”
                                            </p>
                                            <div className="client-info">
                                                <div className="thumbnail">
                                                    <img
                                                        src="assets/images/testimonial/testimonial-02/ridham.jpg"
                                                        alt="CMI - Ridham Kevadiya"
                                                        title="CMI - Ridham Kevadiya"
                                                    /> 
                                                </div>
                                                <div className="content">
                                                    <h6 className="title">Ridham Savaliya</h6>
                                                    <span className="designation">Ui/Ux Designer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Tastimonial Card  */}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="testimonial-four-right-content">
                                <div
                                    className="section-title text-start"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    <span className="pre-title">Testimonials</span>
                                    <h3 className="title">Students Feedback</h3>
                                </div>
                                <p
                                    className="description mt--25 mb--25"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    Codesign Multimedia Institute(CMI) is a reputed training institute in Surat for web design, Game design,Mobile App Development, Web Development & All types of IT with full-fledged, result-oriented training experience.
                                </p>
                                <h6
                                    className="subtitle"
                                    data-sal-delay={150}
                                    data-sal="slide-up"
                                    data-sal-duration={800}
                                >
                                    People Love To Learn With Us
                                </h6>
                                <div className="row g-5">
                                    {/* Start Single Feature  */}
                                    <div
                                        className="col-lg-6"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">90%</h6>
                                                <p className="feature-description">
                                                    90% of students see their course through to completion.{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Feature  */}
                                    {/* Start Single Feature  */}
                                    <div
                                        className="col-lg-6"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="feature-style-3">
                                            <div className="feature-content">
                                                <h6 className="feature-title">9/10</h6>
                                                <p className="feature-description">
                                                    9/10 companies reported better learning outcomes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Feature  */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-04-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-08.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="assets/images/shapes/shape-19-03.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="assets/images/shapes/shape-16-01.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default About