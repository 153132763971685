import { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import axios from "axios";
import { Link } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";

const Comming = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [email, setEmail] = useState('')
    const subscribe = (e) => {
        e.preventDefault()
        axios.post('https://cmiapi-navy.vercel.app/add_newsletter', {
            email: email
        })
            .then(function (res) {
                console.log(res);
                setEmail('')
                toast.success("You will receive our future updates on Email....", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            });
    }
    return (
        <>
            <ToastContainer />
            <Breadcrumb course={"Coming Soon"} />
            <div className="eduvibe-coming-soon edu-coming-soon-area edu-coming-soon-style ptb--150 ptb_md--80 ptb_sm--80 bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-xl-10 offset-xl-1">
                            <div className="content text-center">
                                <div className="logo">
                                    <Link to={'/'}>
                                        <img src="/assets/images/logo/logo.webp" 
                                        alt="Codesign Multimedia - Best It Training Institute In Surat" 
                                        title="Codesign Multimedia - Best It Training Institute In Surat" 
                                        />
                                    </Link>
                                </div>
                                <h1 className="title">
                                    We Are Making <br /> Something Specials
                                </h1>
                                <div className="newsletter-style-6">
                                    <form className="newsletter-form-style-2" action="#">
                                        <input type="email" placeholder="Enter your mail address" onChange={(e) => { setEmail(e.target.value) }} value={email} />
                                        <button className="edu-btn" onClick={subscribe}>
                                            Subscribe Now <i className="icon-arrow-right-line-right" />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/assets/images/shapes/shape-11-06.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/assets/images/shapes/shape-09-02.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/assets/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/assets/images/shapes/shape-14-04.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-5">
                            <img src="/assets/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-6">
                            <img src="/assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Comming;