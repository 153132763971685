import { Link } from "react-router-dom";

const BlogData = (props) => {
    return(
        props.blog.map((item, index) => {
            return (
                <div className="col-lg-4 col-md-6 col-12" key={`Blog${index}`}>
                    <div className="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                        <div className="inner">
                            <div className="thumbnail">
                                <Link to={`/blog/${item._id}`}>
                                    <img
                                        src={item.thumbnail}
                                        alt={`Codesign Blog - ${item.title}`}
                                        title={`Codesign Blog - ${item.title}`}
                                    />
                                </Link>
                            </div>
                            <div className="content">
                                <div className="blog-date-status">
                                    <span className="month">{item.date.substr(0, 6)}</span>
                                </div>
                                <div className="status-group status-style-5">
                                    <Link className="eduvibe-status status-05" to={`/blog/${item._id}`}>
                                        <i className="icon-price-tag-3-line" /> {(item.catagory) ? item.catagory : "Business"}
                                    </Link>
                                    <span className="eduvibe-status status-05">
                                        <i class="fi fi-rr-comment"></i> {item.comment.length} Comments
                                    </span>
                                </div>
                                <h5 className="title">
                                    <Link to={`/blog/${item._id}`}>{item.title}</Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
    )
}
export default BlogData;