import Slider from "../components/Slider"
import WOW from 'wowjs';
import { useEffect } from "react";
import Services from "../components/Services";
import AboutHome from "../components/About";
import CourseHome from "../components/CourseHome";
import Video from "../components/Video";
import Testimonial from "../components/Testimonial";
import Bloghome from "../components/Bloghome";
import { Helmet } from "react-helmet";

const Home = () => {

    var loadScript = function (src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.getElementsByTagName('body')[0].appendChild(tag);
    }

    useEffect(() => {
        window.scrollTo(0,0);
        const wow = new WOW.WOW();
        wow.init();
        loadScript('assets/js/vendor/modernizr.min.js')
        loadScript('assets/js/vendor/jquery.js');
        loadScript('assets/js/vendor/bootstrap.min.js');
        loadScript('assets/js/vendor/sal.min.js');
        loadScript('assets/js/vendor/backtotop.js');
        loadScript('assets/js/vendor/magnifypopup.js');
        loadScript('assets/js/vendor/slick.js');
        loadScript('assets/js/vendor/countdown.js');
        loadScript('assets/js/vendor/jquery-appear.js');
        loadScript('assets/js/vendor/odometer.js');
        loadScript('assets/js/vendor/isotop.js');
        loadScript('assets/js/vendor/imageloaded.js');
        loadScript('assets/js/vendor/lightbox.js');
        loadScript('assets/js/vendor/wow.js');
        loadScript('assets/js/vendor/paralax.min.js');
        loadScript('assets/js/vendor/paralax-scroll.js');
        loadScript('assets/js/vendor/jquery-ui.js');
        loadScript('assets/js/vendor/tilt.jquery.min.js');
        loadScript('assets/js/main.js');
    }, [])


    return (
        <>
            <Helmet>
                <title>Gujarat's 1st Oracle APEX training institute || Oracle APEX training in Surat || Best Oracle APEX Training Institute</title>
                <meta name="title" content="Gujarat's 1st Oracle APEX training institute || Oracle APEX training in Surat || Best Oracle APEX Training Institute" data-rh="true" />
                <meta name="description" content="Looking for the best Oracle APEX training institute in Gujarat? Look no further than our institute! We offer top-notch training in Oracle APEX, the industry-leading low-code development platform. Our experienced instructors provide hands-on training, ensuring you have the skills you need to succeed in your career. Don't settle for anything less than the best - enroll in our Oracle APEX training institute today!" data-rh="true" />
                <meta property="og:title" content="Gujarat's 1st Oracle APEX training institute || Oracle APEX training in Surat || Best Oracle APEX Training Institute" data-rh="true" />
                <meta property="og:description" content="Looking for the best Oracle APEX training institute in Gujarat? Look no further than our institute! We offer top-notch training in Oracle APEX, the industry-leading low-code development platform. Our experienced instructors provide hands-on training, ensuring you have the skills you need to succeed in your career. Don't settle for anything less than the best - enroll in our Oracle APEX training institute today!" data-rh="true" />
                <meta name="twitter:title" content="Gujarat's 1st Oracle APEX training institute || Oracle APEX training in Surat || Best Oracle APEX Training Institute" data-rh="true" />
                <meta name="twitter:description" content="Looking for the best Oracle APEX training institute in Gujarat? Look no further than our institute! We offer top-notch training in Oracle APEX, the industry-leading low-code development platform. Our experienced instructors provide hands-on training, ensuring you have the skills you need to succeed in your career. Don't settle for anything less than the best - enroll in our Oracle APEX training institute today!" data-rh="true" />
            </Helmet>
            <Slider />
            <Services />
            <AboutHome />
            <CourseHome />
            <Video />
            <Testimonial />
            <Bloghome />
        </>
    )
}

export default Home