import axios from "axios";
import { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { Helmet } from "react-helmet";
import { Bounce, ToastContainer, toast } from "react-toastify";
import BlogData from "../components/BlogData";
import Skeletoon from "../components/Skeletoon";

const Blog = () => {

    const [blog, setBlog] = useState([]);
    const [isdata, setIsdata] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get('https://cmiapi-navy.vercel.app/get_blog')
            .then(function (res) {
                setBlog(res.data.data)
                setIsdata(true)
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
    }, [])

    return (
        <>
            <ToastContainer />
            <Helmet>
                <title>Codesign: Blog & Reviews for Surat Courses</title>
                <meta name="title" content="Computer Training in Surat | Codesign" data-rh="true" />
                <meta name="description" content="Get the best computer course training in Surat with Codesign Multimedia Institute. Learn coding and design from industry experts today." data-rh="true" />
                <meta property="og:title" content="Computer Training in Surat | Codesign" data-rh="true" />
                <meta property="og:description" content="Get the best computer course training in Surat with Codesign Multimedia Institute. Learn coding and design from industry experts today." data-rh="true" />
                <meta name="twitter:title" content="Computer Training in Surat | Codesign" data-rh="true" />
                <meta name="twitter:description" content="Get the best computer course training in Surat with Codesign Multimedia Institute. Learn coding and design from industry experts today." data-rh="true" />
            </Helmet>
            <Breadcrumb course="Blog" />
            <div className="edu-elements-area edu-section-gap bg-color-white">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-12">
                            <div className="row g-5">
                                {
                                    (isdata) ? <BlogData blog={blog} /> : (<Skeletoon/>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default Blog