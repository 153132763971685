import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { useEffect, useState } from "react";
import axios from "axios";
import { Accordion, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Bounce, ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Skeletoon from "../components/Skeletoon";

const CourseSingle = () => {

    const params = useParams();
    const { id } = params;
    const [course, setCourse] = useState();
    const [data, setData] = useState(false);
    const [courses, setCourses] = useState([]);
    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [addcourse, setAddcourse] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`https://cmiapi-navy.vercel.app/course/${id}`)
            .then(function (res) {
                setCourse(res.data.data)
                setData(true);
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })

        axios.get(`https://cmiapi-navy.vercel.app/getall_courses`)
            .then(function (res) {
                setCourses(res.data.data)
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                });
            })
    }, [id])

    const add_inquiry = (e) => {
        e.preventDefault()
        axios.post('https://attractive-gold-wetsuit.cyclic.app/add_inquiry', {
            name: name,
            email: email,
            course: addcourse
        })
            .then(function (res) {
                toast.success("Thank you !! Our team will contact you soon....", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setName('')
                setEmail('');
                setAddcourse('');
                setShow(false)
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            });
    }

    return (
        <>
            <ToastContainer/>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="model_header">
                    <Modal.Title>Sign up for a
                        free trial lesson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        className="rnt-contact-form rwt-dynamic-form row"
                        id="contact-form"
                    >
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input
                                    name="contact-name"
                                    id="contact-name"
                                    type="text"
                                    value={name}
                                    onChange={(e) => { setName(e.target.value) }}
                                    className="form-control form-control-lg"
                                    placeholder="Name*"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control form-control-lg"
                                    onChange={(e) => { setEmail(e.target.value) }}
                                    id="contact-email"
                                    value={email}
                                    name="contact-email"
                                    placeholder="Email*"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <select name="course" id="COURSE" value={addcourse} onChange={(e) => { setAddcourse(e.target.value) }}>
                                    <option value="" disabled>Select Course</option>
                                    {
                                        courses.map((item, i) => {
                                            return (<option key={`option` + i} value={item.title}>{item.title}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button className="edu-btn" onClick={add_inquiry}>Send Request</button>
                    </div>
                </Modal.Footer>
            </Modal>
            {
                (data) ? (
                    <>
                        <Breadcrumb course={course.title} />
                        <Helmet>
                            <title>{course.meta_title}</title>
                            <meta name="title" content={course.meta_title} data-rh="true" />
                            <meta name="description" content={course.meta_description} data-rh="true" />
                            <meta property="og:title" content={course.meta_title} data-rh="true" />
                            <meta property="og:description" content={course.meta_description} data-rh="true" />
                            <meta name="twitter:title" content={course.meta_title} data-rh="true" />
                            <meta name="twitter:description" content={course.meta_description} data-rh="true" />
                        </Helmet>
                        <div className="edu-course-details-area edu-section-gap bg-color-white">
                            <div className="container">
                                <div className="row g-5">
                                    <div className="col-lg-12">
                                        <div className="main-image thumbnail">
                                            <img
                                                className="radius-small"
                                                src={course.image}
                                                alt={course.meta_title}
                                                title={course.meta_title}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row g-5">
                                    <div className="col-xl-8 col-lg-7">
                                        <div className="course-details-content">
                                            <div className="content-top">

                                            </div>
                                            <h3 className="title">
                                                {course.title}
                                            </h3>
                                            <div className="course-details-card">
                                                <div className="course-content">
                                                    <h5>Course Overview</h5>
                                                    {
                                                        course.description.map((item, i) => {
                                                            return (
                                                                <p key={`para${i}`}>
                                                                    {item}
                                                                </p>
                                                            )
                                                        })
                                                    }
                                                    <h5>What You’ll Learn From This Course</h5>
                                                    <ul>
                                                        <li>
                                                            {course.what_learn}
                                                        </li>
                                                    </ul>
                                                    <h5>Certification</h5>
                                                    <p>
                                                        We are committed to providing globally recognized certification upon completion of our courses. Our certification is accepted industrywide and reflects the quality and expertise of our programs. You can be confident that our courses will prepare you for success in your field and give you a competitive edge in the global job market.
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="course-details-card mt--40">
                                                <h4>Topics</h4>
                                                <div className="course-content">
                                                    <div className="edu-accordion-02" id="accordionExample1">
                                                        <div className="edu-accordion-item">
                                                            {
                                                                course.topics.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <Accordion defaultActiveKey="0" className="acc-btn" key={`acc${index}`}>
                                                                                <Accordion.Item eventKey="1" >
                                                                                    <Accordion.Header className="header-acco">{item.main}</Accordion.Header>
                                                                                    <Accordion.Body className="edu-accordion-body">
                                                                                        <ul>
                                                                                            {
                                                                                                item.sub.map((item, i) => {
                                                                                                    return (
                                                                                                        <li key={`item${i}`}>
                                                                                                            <div className="text">
                                                                                                                <i className="icon-draft-line" /> {item}
                                                                                                            </div>
                                                                                                            <div className="icon">
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-details-card mt--40">
                                                <h4>Frequently Asked Questions</h4>
                                                <div className="course-content">
                                                    <div className="edu-accordion-02" id="accordionExample1">
                                                        <div className="edu-accordion-item">
                                                            {
                                                                course.faq.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <Accordion defaultActiveKey="0" className="acc-btn" key={`faq${i}`}>
                                                                                <Accordion.Item eventKey="1" >
                                                                                    <Accordion.Header className="header-acco">{item.que}</Accordion.Header>
                                                                                    <Accordion.Body className="edu-accordion-body">
                                                                                        <ul className="list_style mx-5">
                                                                                            <li>
                                                                                                <div className="text">
                                                                                                    <i className="icon-draft-line" /> {item.ans}
                                                                                                </div>
                                                                                                <div className="icon">
                                                                                                </div>
                                                                                            </li>

                                                                                        </ul>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-5">
                                        <div className="eduvibe-sidebar course-details-sidebar">
                                            <div className="inner">
                                                <div className="eduvibe-widget">
                                                    <div className="course-details-price">
                                                        <p className="course-details-price-text">To Start Your</p>
                                                        <p className="course-details-price-amount">DEMO LACTURE</p>
                                                        <button className="theme-btn btn-style-two course-details-price-btn" onClick={handleShow}>BOOK SEAT NOW</button>
                                                    </div>
                                                    <div className="edu-blog-widget mt--40 widget-latest-post">
                                                        <div className="inner">
                                                            <h5 className="widget-title">Our Courses</h5>
                                                            <div className="content latest-post-list">
                                                                {
                                                                    courses.map((item, i) => {
                                                                        return (
                                                                            <div className="latest-post" key={`course${i}`}>
                                                                                <div className="thumbnail">
                                                                                    <Link to={`/course/${item._id}`}>
                                                                                        <img
                                                                                            src={item.thumbnail}
                                                                                            alt={item.meta_title}
                                                                                            title={item.meta_title}
                                                                                            className="thumbnail-img"
                                                                                        />
                                                                                    </Link>
                                                                                </div>
                                                                                <div className="post-content">
                                                                                    <h6 className="title">
                                                                                        <Link to={`/course/${item._id}`}>{item.title}</Link>
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="container">
                        <div className="row">
                            <Skeletoon />
                        </div>
                    </div>
                )
            }
        </>

    )
}

export default CourseSingle;