import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Course from './pages/Course';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import CourseSingle from './pages/CourseSingle';
import BlogSingle from './pages/BlogSingle';
import Comming from './pages/Comming';

function App() {
  return (
    <div className="main-wrapper">
      <Header/>
        <Routes>
          <Route path='/*' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/course' element={<Course/>}/>
          <Route path='/blog' element={<Blog/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/course/:id' element={<CourseSingle/>}/>
          <Route path='/blog/:id' element={< BlogSingle/>}/>
          <Route path='/event' element={< Comming/>}/>
          <Route path='/gallery' element={< Comming/>}/>
        </Routes>
        <Footer/>
    </div>
  );
}

export default App;
