import { Link } from "react-router-dom";
import { SlSocialFacebook, SlSocialInstagram, SlSocialLinkedin, SlSocialTwitter } from "react-icons/sl";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import axios from "axios";
import { Bounce, toast } from "react-toastify";

const Header = () => {

    const [isactive, setIsactive] = useState(false)
    const [index, setIndex] = useState('Home');
    const [show, setShow] = useState(false);
    const [regId, setRegId] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const active = (id) => {
        setIndex(id);
        sessionStorage.setItem("Nav", id)
    }

    const certificate = () => {
        if (regId) {
            toast.success("Wait We Are Checking Data...", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            axios.get(`https://cmiapi-navy.vercel.app/get_certificate/${regId}`)
                .then(function (res) {
                    console.log(res.data.data);
                    window.open(`${res.data.data[0].filePath}`, "_blank");
                    handleClose()
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error("Maybe You Entered Wrong Registration Id", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                })
        }else{
            toast.error("Please Enter Valid Registration Id", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }

    useEffect(() => {
        setIndex(sessionStorage.getItem("Nav"))
    }, [])


    return (
        <>
            <header className="edu-header disable-transparent  header-sticky">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="logo">
                                <Link to={'/'}>
                                    <img
                                        className="logo-light"
                                        src="/assets/images/logo/logo.webp"
                                        alt="Codesign"
                                        title="Codesign"
                                    />
                                </Link>
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Verify Certificate Here</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="subject"
                                        name="Enter Your Registration Number"
                                        placeholder="Enter Your Registration Number"
                                        onChange={(e) => { setRegId(e.target.value) }}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" className="modal-btn" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" className="modal-btn" onClick={certificate}>
                                    Verify
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <div className="col-lg-8 d-none d-xl-block">
                            <nav className="mainmenu-nav d-none d-lg-block">
                                <ul className="mainmenu">
                                    <li className="has-droupdown">
                                        <Link to={'/'} className={(index === 'Home') ? "active" : ""} onClick={() => { active('Home') }}>Home</Link>
                                    </li>
                                    <li className="has-droupdown">
                                        <Link to={'/about'} className={(index === 'About') ? "active" : ""} onClick={() => { active('About') }}>About</Link>
                                    </li>
                                    <li className="has-droupdown">
                                        <Link to={'/course'} className={(index === 'Courses') ? "active" : ""} onClick={() => { active('Courses') }}>Courses</Link>
                                    </li>
                                    <li className="has-droupdown">
                                        <Link to={'/blog'} className={(index === 'Blog') ? "active" : ""} onClick={() => { active('Blog') }}>Blog</Link>
                                    </li>
                                    <li className="has-droupdown">
                                        <Link to={'/contact'} className={(index === 'Contact') ? "active" : ""} onClick={() => { active('Contact') }}>Contact</Link>
                                    </li>
                                    <li>
                                        <a className="certificate-link" onClick={handleShow}>Verify Certificate</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-xl-2 col-md-6 col-6">
                            <div className="header-right d-flex justify-content-end">
                                <div className="header-quote">
                                    <div className="quote-icon quote-search">
                                        <a href="https://www.instagram.com/codesign_multimedia_institute/" target="_blank" rel="noreferrer">
                                            <SlSocialInstagram />
                                        </a>
                                    </div>
                                    <div className="quote-icon quote-user">
                                        <a href="https://www.facebook.com/profile.php?id=100088854465381" target="_blank" rel="noreferrer">
                                            <SlSocialFacebook />
                                        </a>
                                    </div>
                                    <div className="quote-icon quote-user">
                                        <a href="https://www.linkedin.com/company/90445650/admin/" target="_blank" rel="noreferrer">
                                            <SlSocialLinkedin />
                                        </a>
                                    </div>
                                    <div className="quote-icon quote-user">
                                        <a href="https://twitter.com/Codesign402?t=7T69Ab-xm1UqvDxx15PY3Q&s=08" target="_blank" rel="noreferrer">
                                            <SlSocialTwitter />
                                        </a>
                                    </div>
                                    <div className="hamberger quote-icon d-block d-xl-none" onClick={() => { setIsactive(!isactive) }}>
                                        <button className="hamberger-button">
                                            <i className="ri-menu-line" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`popup-mobile-menu ${(isactive) ? "active" : ""}`} onClick={() => { setIsactive(!isactive) }}>
                <div className="inner">
                    <div className="header-top">
                        <div className="logo" onClick={() => { setIsactive(!isactive) }}>
                            <Link to={'/'}>
                                <img
                                    className="logo-light"
                                    src="/assets/images/logo/logo.webp"
                                    alt="Codesign"
                                    title="Codesign"
                                />
                            </Link>
                        </div>
                        <div className="close-menu">
                            <button className="close-button" onClick={() => { setIsactive(!isactive) }}>
                                <i className="ri-close-line" />
                            </button>
                        </div>
                    </div>
                    <ul className="mainmenu">

                        <li onClick={() => { setIsactive(!isactive) }}>
                            <Link to={'/'} className={(index === 'Home') ? "active" : ""} onClick={() => { active('Home') }}>Home</Link>
                        </li>
                        <li onClick={() => { setIsactive(!isactive) }}>
                            <Link to={'/about'} className={(index === 'About') ? "active" : ""} onClick={() => { active('About') }}>About</Link>
                        </li>
                        <li onClick={() => { setIsactive(!isactive) }}>
                            <Link to={'/course'} className={(index === 'Course') ? "active" : ""} onClick={() => { active('Course') }}>Courses</Link>
                        </li>
                        <li onClick={() => { setIsactive(!isactive) }}>
                            <Link to={'/blog'} className={(index === 'Blog') ? "active" : ""} onClick={() => { active('Blog') }}>Blog</Link>
                        </li>
                        <li onClick={() => { setIsactive(!isactive) }}>
                            <Link to={'/contact'} className={(index === 'Contact') ? "active" : ""} onClick={() => { active('Contact') }}>Contact</Link>
                        </li>
                        <li>
                            <p className="certificate-link" onClick={handleShow}>Verify Certificate</p>
                        </li>
                    </ul>
                </div>
            </div>

        </>

    )
}

export default Header;