import { Link } from "react-router-dom";

const Video = () => {
    return (
        <div className="eduvibe-home-four-video edu-video-area edu-section-gap video-style-2">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="pr--75 pr_lg--30 pr_md--0 pr_sm--0">
                            <div className="thumbnail video-popup-wrapper">
                                <img
                                    className="radius-small"
                                    src="assets/images/videopopup/video-popup-bg-03.jpg"
                                    alt="Codesign - Video Thumbnail"
                                />
                                <a
                                    href="https://www.youtube.com/watch?v=mqtNpLqbXEk"
                                    className="video-play-btn with-animation position-to-top video-popup-activation color-secondary size-80"
                                >
                                    <span className="play-icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="content mt_md--40 mt_sm--40">
                            <div
                                className="section-title text-start"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">Learn From Today</span>
                                <h3 className="title">Transform Your Life Through Education</h3>
                            </div>
                            <p
                                className="description mt--40 mb--40 mt_md--20 mt_sm--20"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                Codesign Multimedia Institute(CMI) is a reputed training institute in Surat for web design, Game design,Mobile App Development, Web Development & All types of IT with full-fledged, result-oriented training experience.
                            </p>
                            <div
                                className="read-more-btn text-start"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <Link className="edu-btn" to={'/course'}>
                                    Know More
                                    <i className="icon-arrow-right-line-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-03-10.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-19-03.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
            <div className="side-shape-image d-lg-block d-none">
                <img
                    src="assets/images/videopopup/video-infinite-rotate.png"
                    alt="Shape Images"
                />
            </div>
        </div>

    )
}

export default Video;