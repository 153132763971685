import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Bounce, ToastContainer, toast } from 'react-toastify';

const Bloghome = () => {

    const [blog, setBlog] = useState([]);

    useEffect(() => {
        axios.get('https://cmiapi-navy.vercel.app/get_blog')
            .then(function (res) {
                for (var i = 0; i < res.data.data.length; i++) {
                    setBlog(res.data.data.slice(0, 3))
                }
            })
            .catch(function (error) {
                console.log(error);
                toast.error(error.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            })
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="eduvibe-home-four-blog bg-image edu-blog-area edu-section-gap">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div
                            className="col-lg-6 col-md-6 col-12"
                            data-sal-delay={150}
                            data-sal="slide-up"
                            data-sal-duration={800}
                        >
                            <div className="section-title text-start">
                                <span className="pre-title">Blog &amp; News</span>
                                <h3 className="title">Our Latest News Feed</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="load-more-btn text-start text-md-end">
                                <Link className="edu-btn" to={'/blog'}>
                                    Browse All Post
                                    <i className="icon-arrow-right-line-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">

                        {
                            blog.map((item, index) => {
                                return (
                                    <div
                                        key={`my${index}`}
                                        className="col-lg-4 col-md-6 col-12"
                                        data-sal-delay={150}
                                        data-sal="slide-up"
                                        data-sal-duration={800}
                                    >
                                        <div className="edu-blog blog-type-2 variation-2 radius-small">
                                            <div className="inner">
                                                <div className="thumbnail">
                                                    <Link to={`/blog/${item._id}`}>
                                                        <img
                                                            src={item.thumbnail}
                                                            alt={`Codesign - ${item.title}`}
                                                            title={`Codesign - ${item.title}`}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="content">
                                                    <div className="blog-date-status">
                                                        <span className="month">{item.date.substr(0, 6)}</span>
                                                    </div>
                                                    <div className="status-group status-style-5">
                                                        <Link to={`/blog/${item._id}`} className="eduvibe-status status-05">
                                                            <i className="icon-price-tag-3-line" /> Education
                                                        </Link>
                                                        <Link to={`/blog/${item._id}`} className="eduvibe-status status-05">
                                                            <i class="fi fi-rr-comment"></i> {item.comment.length} Comments
                                                        </Link>
                                                    </div>
                                                    <h5 className="title">
                                                        <Link to={`/blog/${item._id}`}>{item.title}</Link>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/assets/images/shapes/shape-32.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/assets/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/assets/images/shapes/shape-27-01.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Bloghome;